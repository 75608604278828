const initialState = {
  tickets: {
    visibleFields: "",
    filterFields: "",
  },
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TICKETS_FILTER":
      return {
        ...state,
        tickets: {
          visibleFields: action.payload.visibleFields || "",
          filterFields: action.payload.filterFields || "",
        },
      };
    default:
      return state;
  }
};

export default filterReducer;
